import React, {Component} from 'react';
import Slider from "react-slick";
import {Link} from 'react-router-dom';

//Images
import industry1 from './../../images/our-services/industry/pic1.jpg';
import industry2 from './../../images/our-services/industry/pic2.jpg';
import industry3 from './../../images/our-services/industry/pic3.jpg';
import industry4 from './../../images/our-services/industry/pic4.jpg';
import industry5 from './../../images/our-services/industry/pic5.jpg';
import industry6 from './../../images/our-services/industry/pic6.jpg';


const contentBlog =[
	{images: industry1, link: "/infrastructure", title: 'CLOUD, INFRASTRUCTURE AND SYSTEM SERVICES', body: "One of the advantages of moving business to the cloud is that it provides companies with noteworthy savings..."}, 
	{images: industry2, link: "/consultancy", title: 'CONSULTANCY AND ICT STRATEGY / SOLUTIONS', body: "Assisting our customers increase efficiency in their consumption of their technology tools; both software and hardware..." }, 
	{images: industry3, link: "/licensing", title: 'LICENSING, CAPACITY BUILDING AND TRAINING', body: "Focused in promoting knowledge sharing and the further development of its customers both at the public and private sectors..." }, 
	{images: industry4, link: "/messaging", title: 'MESSAGING,COLLABORATION & UC SOLUTIONS', body: "Having a platform to communicate, exchange documents and ideas in a secured but yet optimized and easy to use form is part..." },
	{images: industry5, link: "/project", title: 'PROJECT AND HUMAN RESOURCE MANAGEMENT',body: "we manage human capital of organizations focusing on implementing policies to move globally and form more diverse teams..." },
	{images: industry6, link: "/management", title: 'IT MANAGEMENT', body: "Offering full time, weekly or on call support assistance to your infrastructure to meet your needs is our way to ensure your business stays..."}, 
]

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
  	<div className="owl-nav">
		<div className="owl-next flaticon-right-arrow"  onClick={onClick}/>
	</div>	
  );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
		<div className="owl-nav">
			<div className=" owl-prev flaticon-left-arrow" onClick={onClick} style={{zIndex:1}}/>
		</div>
    );
} 

class ExploreCarousel extends Component{
	render(){
		var settings = {		
			arrows: true,
            slidesToShow: 3,		
            infinite: true,
			dots: false,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 2,
				  }
				},
				{
				  breakpoint: 480,
				  settings: {
					slidesToShow:1,
				  }
				}
			]
        };
		return(
			<>
				<Slider className="explore-carousel mfp-gallery owl-loaded owl-theme owl-carousel gallery owl-btn-center-lr owl-btn-2 primary " {...settings}>
					{contentBlog.map((data,index)=>(
						<div className="item p-3" key={index}>
							<div className="dlab-box">
								<div className="dlab-media dlab-img-effect rotate"> <Link to={"#"}><img src={data.images} alt="" /></Link> </div>
								<div className="dlab-info bg-white">
									<h5 className="dlab-title m-t0"><Link to={"#"}>{data.title}</Link></h5>
									<p className="m-b0">{data.body}</p>
								</div>
								<Link to={data.link} className="site-button btn-block d-flex justify-content-between white"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
							</div>
						</div>
					))}					
				</Slider>
			</>
		)
	}
	
}

export default ExploreCarousel;