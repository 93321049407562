import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PageTitle from './../Layout/PageTitle';
import ImgCarousel from './../Element/ImgCarousel'
import ClientCarousel from './../Element/ClientCarousel'

//Images 
import bnr1 from './../../images/banner/bnr2.jpg';

import Footer5 from '../Layout/Footer5';
import Header3 from '../Layout/header3';

class Licensing extends Component {
    /* componentDidMount(){
        window.abc();
    } */
    render() {
        return (
            <> 
            <Header3 />            
            <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='LICENSING, CAPACITY BUILDING AND TRAINING' activeMenu='messaging' />  
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- Project Details --> */}
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="row m-b40">
                                    <div className="col-lg-6 col-md-6 m-b20">
                                        <h6 className="m-b10 m-t0">FOCUSED IN PROMOTING KNOWLEDGE SHARING AND THE FURTHER DEVELOPMENT OF ITS CUSTOMERS BOTH AT THE PUBLIC AND PRIVATE SECTORS, BE IT MANAGED AND FUNDED BY THE GOVERNMENT OR FOREIGN PARTNERS.</h6>
                                        
                                        <p className="m-b10">As partner to world class brands such as Microsoft, Cisco, Dell & Hewlett-Packard, we operate within approved standards and ensure our clients do not fall short of requirements in consuming any of the services. We conduct licensing assessments and consultations keeping our customers abreast on the new developments in the IT world as updates come from the manufacturers in real time. We deliver extensive capacity building and training programs which aim to help develop the skills and institutional capacity needed to administer, manage and use intellectual property. Our trainings cut across personal, public and private engagements.

It is one of our key deliverables to train and equip all stake holders engaged in projects with us to ensure they have the necessary skill and knowhow to take over ownership of the newly deployed system and ensure its continuous functionality while we support actively to attain highest possible uptime.</p>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
										<ImgCarousel /> 
                                    </div>
                                </div>
                                <div className="row m-b10">                                    
                                    <div className="col-md-12 align-self-center">
                                    </div>
                                </div>
                            </div>
                            <div className="container"> 
                                <div className="row m-b30">
                                    <div className="col-lg-4 col-md-12 align-self-center">
                                        <h5>Who We Are</h5>
                                        <h2 className="font-weight-700">Leading The Way In IT Consulting Business For Over 25 Years</h2>
                                    </div>
                                    <div className="col-lg-8 col-md-12">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="90" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">90%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Day Of Experience</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="49" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">90%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Awards Winning</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="65" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">90%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Happy Clients</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
								<ClientCarousel /> 
                            </div>
                        </div>
                    </div>
                    {/* <!-- contact area END --> */}
                </div>
                <Footer5 />
            </>
        )
    }
}
export default Licensing;