import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PageTitle from './../Layout/PageTitle';
import ImgCarousel from './../Element/ImgCarousel'
import ClientCarousel from './../Element/ClientCarousel'

//Images 
import bnr1 from './../../images/banner/bnr2.jpg';

import Footer5 from '../Layout/Footer5';
import Header3 from '../Layout/header3';

class Messaging extends Component {
    /* componentDidMount(){
        window.abc();
    } */
    render() {
        return (
            <> 
            <Header3 />            
            <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='CONSULTANCY AND ICT STRATEGY / SOLUTIONS' activeMenu='messaging' />  
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- Project Details --> */}
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="row m-b40">
                                    <div className="col-lg-6 col-md-6 m-b20">
                                        <h6 className="m-b10 m-t0">ASSISTING OUR CUSTOMERS INCREASE EFFICIENCY IN THEIR CONSUMPTION OF THEIR TECHNOLOGY TOOLS; BOTH SOFTWARE AND HARDWARE, FINESSE TECH ARE KNOWN FOR OFFERING AND DELIVERY OF PROFESSIONAL, HIGH END CONSULTATION SERVICES TO ITS CUSTOMERS.</h6>
                                        
                                        <p className="m-b10">Working with a unique ICT strategy development model developed and remodeled over experience on a winning solution and foundation, it is used in the process of developing our clients businesses. It starts with identifying the business value model and determining the key area of growth needed from the technological solution before diving into any technicalities. Our Consultancy and Strategy solutions centered around but not limited to the following areas Software and bespoke solution development, Business Systems and Data Analytics, Server Infrastructure, Information Security, Networking and Network vulnerability assessment, Enterprise Architecture & Enterprise Business solutions, Knowledge development in training and Capacity building, Infrastructure and on site resource management.</p>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
										<ImgCarousel /> 
                                    </div>
                                </div>
                                <div className="row m-b10">                                    
                                    <div className="col-md-12 align-self-center">
                                        <p className="m-b0">Our ICT Strategy services allow for our clients to gain better understanding of their environment, gather more knowledge and also optimize the power of planning and proper integration of their IT infrastructure. Consultancy services cover future proofing and forward planning of our clients’ environment, Procurement, deployment and user testing services which meet global accepted standards in both the public and private sectors. Our aim and focus is for the delivery of high outstanding solutions which are differentiators in the market that enable our customers achieve their business goals. Our approach tightly integrates with the underlining business as we focus on delivering custom tailored services to suit business and not just a conventional ICT strategy.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row m-b30">
                                    <div className="col-lg-4 col-md-12 align-self-center">
                                        <h5>Who We Are</h5>
                                        <h2 className="font-weight-700">Leading The Way In IT Consulting Business For Over 25 Years</h2>
                                    </div>
                                    <div className="col-lg-8 col-md-12">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="90" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">90%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Day Of Experience</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="49" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">90%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Awards Winning</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="65" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">90%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Happy Clients</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
								<ClientCarousel /> 
                            </div>
                        </div>
                    </div>
                    {/* <!-- contact area END --> */}
                </div>
                <Footer5 />
            </>
        )
    }
}
export default Messaging;