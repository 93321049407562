import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';

import PageTitle from './../../Layout/PageTitle';

// var bg1 = require('images/banner/bnr2.jpg');
import bnr1 from './../../../images/banner/bnr1.jpg';
import bnr2 from './../../../images/background/bg1.png';
import about1 from './../../../images/about/pic1.jpg';
import about2 from './../../../images/about/pic2.jpg';
import about3 from './../../../images/about/pic3.jpg';
import bnr3 from './../../../images/pattern/pic1.jpg';
import work1 from './../../../images/our-work/pic1.jpg';
import work3 from './../../../images/our-work/pic3.jpg';
import work5 from './../../../images/our-work/pic5.jpg';
import bnr4 from './../../../images/background/bg1.jpg';
import Footer5 from '../../Layout/Footer5';
import Header3 from '../../Layout/header3';

const imageblog =[
	{image: work1, title:'Understand the situation',},
	{image: work3, title:'Bring the experience to life',},
	{image: work5, title:'Strategise chart the course',},
];
const counterBlog = [
	{icon: <i className="icon flaticon-bar-chart text-primary" />, num: '4922',  title: 'Completed Projects',},	
	{icon: <i className="icon flaticon-avatar text-primary" />, num: '2934',    title: 'Happy Clients',},	
	{icon: <i className="icon flaticon-file text-primary" />, num: '2296',   title: 'Questions Answered',},	
	{icon: <i className="icon flaticon-pen text-primary" />, num: '1343', title: 'Ordered Coffees',},	
];

class Aboutus2 extends Component {
    render() {
        return (
            <>
              <Header3 />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr dlab-bnr-inr-sm overlay-primary bg-pt" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='About Us' activeMenu='About Us' />
                    </div>
                    <div className="content-block">
						<div className="section-full bg-white content-inner" style={{ backgroundImage: "url(" + bnr2 + ")" }}>
                            <div className="container">
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-6 col-lg-6 col-md-6 col-sm-6">
                                            <div className="icon-bx-wraper">
                                                <div className="icon-md text-black m-b20">
                                                    <Link to ={"#"} className="icon-cell text-black"><i className="flaticon-trophy"></i></Link>
                                                </div>
                                                <div className="icon-content m-b30">
                                                    <h2 className="dlab-tilte">OUR VISION</h2>
                                                    <p>Our vision is to be a top Global ICT Solution provider by consistently delivering quality service, maintaining very high level technical expertise, while focusing on 100% customer satisfaction.</p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                       
                                        <div className="col-6 col-lg-3 col-md-6 col-sm-6 m-b30">
                                            <div className="dlab-media dlab-img-overlay6 gradient radius-sm">
                                                <img src={about1} alt="" />
                                            </div>
                                        </div>
                                        <div className="col-6 col-lg-3 col-md-6 col-sm-6" >
                                            <div className="dlab-media dlab-img-overlay6 gradient radius-sm">
                                                <img src={about2} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-full ">
                            <div className="container-fluid">
                                <div className="row dzseth">
                                    <div className="col-lg-6 col-md-6 col-xs-12 p-a0 img-fix">
                                        <img src={about3} alt="" className="img-cover" />
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-xs-12 bg-black-light p-lr0 dis-tbl">
                                        <div className="p-a30 dis-tbl-cell">
                                            <div className="max-w500 m-auto p-tb30">
                                                <div className="row">
                                                    <div className="col-lg-12 text-white">
                                                        <h2>WHO WE ARE</h2>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-lg-12 m-b30 text-gray">
                                                        <p>Finesse Integrated Technologies is a leading international technology firm with focus on serving the needs of businesses organizations by providing leading-edge technology solutions and services that help our customers operate their businesses efficiently, increase employee productivity, and reduce cost of</p>
                                                        <p>doing business. Established in 2006, Finesse Tech currently provides solutions and services for its various clients in Nigeria, Cameroun, Ghana and UAE with a team of experienced and certified consultants.</p>
                                                        <Link to ={"#"} className="site-button radius-xl outline white">Read More <i className="fa fa-long-arrow-right"></i></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <Footer5 />

            </>
        )
    }
}
export default Aboutus2;