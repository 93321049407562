import React,{Component} from 'react';
import {Link} from 'react-router-dom';

class HeaderMenu extends Component{
	render(){
		return(
			<>
				<ul className="nav navbar-nav">
					<li className="active"><Link to={'/'}><span className="ti-home"></span> </Link>
						
					</li>
					<li><Link to={'./aboutus'} className="dez-page">About Us</Link></li>
					<li><Link to={'./service'} className="dez-page">Our Service</Link></li>
					<li><Link to={'./contact'} className="dez-page">Contact Us</Link></li>																								
				</ul>
			</>
		)
	}
}
export default HeaderMenu;