import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ThemeButton from './Element/ThemeButton/ThemeButton';
import Homepage from './Pages/Index5';
import Error404 from './Pages/Error/Error404';
import UnderMaintenance from './Pages/UnderMaintenance';
import Contact from './Pages/Contact';
import Service from './Pages/Service/Service';
import Infrastructure from './Pages/Infrastructure';

import Consultancy from './Pages/Consultancy';

import ScrollToTop from './Element/ScrollToTop';
import Management from './Pages/Management';
import Messaging from './Pages/Messaging';
import Project from './Pages/Project';
import Licensing from './Pages/Licensing';
import Aboutus2 from './Pages/Aboutus/Aboutus2';
import OurEthics from './Pages/OurEthics';
import Privacy from './Pages/Privacy';

class Markup extends Component {
	render(){
		return(
			<BrowserRouter basename="/">
                <div className="page-wraper">
                    <Switch>
                        <Route path='/' exact component={Homepage} />                       
                        <Route path='/error-404' exact component={Error404} />
                        <Route path='/aboutus' exact component={Aboutus2} />
                        <Route path='/under-maintenance' exact component={UnderMaintenance} />                        
                        <Route path='/contact' exact component={Contact} />
                        <Route path='/service' exact component={Service} />
                        <Route path='/Infrastructure' exact component={Infrastructure} />
                        <Route path='/consultancy' exact component={Consultancy} />  
                        <Route path='/licensing' exact component={Licensing} />  
                        <Route path='/messaging' exact component={Messaging} />  
                        <Route path='/project' exact component={Project} />  
                        <Route path='/management' exact component={Management} />
                        <Route path="/ethics" exact component={OurEthics} />
                        <Route path="/privacypolicy" exact component={Privacy} />
					</Switch>
                </div>
				<ScrollToTop />
				<ThemeButton />
            </BrowserRouter>	
		)
	}
	
}

export default Markup;