import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header3 from './../Layout/header3';
import GoogleMaps from "simple-react-google-maps";
import ExploreCarousel from './../Element/ExploreCarousel';
import Index5Slider from './../Element/Index5Slider';
import Card from '@mui/material/Card';
import bg17 from './../../images/background/bg17.jpg'; 
import bg3 from './../../images/background/bg3.png'; 
import bnr3 from './../../images/background/bg3.jpg'; 
import about11 from './../../images/about/pic11.jpg'; 
import Footer5 from '../Layout/Footer5';
import ClientCarousel from '../Element/ClientCarousel';


class Index5 extends Component {

    componentDidMount() {
       var i = 0;
		
		// Placeholder Animation Start
		var inputSelector = document.querySelectorAll('input, textarea');
		
		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('focus', function(event) {
				return this.parentElement.parentElement.classList.add("focused");
			});
		}
		
		
		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('blur', function(event) {
				var inputValue = this.value;	
				if ( inputValue === '' ) {
					this.parentElement.parentElement.classList.remove('filled');
					this.parentElement.parentElement.classList.remove('focused');  
				} else {
					this.parentElement.parentElement.classList.add('filled');
				}
			});
		}
    }

    render() {
        return (
            <>
                <Header3 />

                <div className="page-content bg-white rubik-font">
					<div className="owl-slider-banner main-slider">
						<Index5Slider />                                              
					</div>
                    <div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full industry-service" style={{ backgroundImage: "url(" + bg17 + ")" }}>
                            <div className="container">
                                
                                <div className="row m-b80">
                                    <div className="col-lg-4 col-md-4">
                                        <Card className="icon-bx-wraper ind-ser-bx">
                                            <div className="icon-lg m-b10">
                                                <Link to={"#"} className="icon-cell text-primary"><i className="flaticon-operation"></i></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="dlab-tilte">Dedicated Teams</h3>
                                                <p>Our professionals work with love and passion for every detail, creating this community of satisfied customers.</p>
                                            </div>
                                            <Link to={"#"} className="site-button btn-block d-flex justify-content-between"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </Card>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <Card className="icon-bx-wraper ind-ser-bx">
                                            <div className="icon-lg m-b10">
                                                <Link to={"#"} className="icon-cell text-primary"><i className="flaticon-network"></i></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="dlab-tilte">True Partners</h3>
                                                <p>The best and most productive relationships are synergistic and goal-oriented</p>
                                            </div>
                                            <Link to={"#"} className="site-button btn-block d-flex justify-content-between"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </Card>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <Card className="icon-bx-wraper ind-ser-bx">
                                            <div className="icon-lg m-b10">
                                                <Link to={"#"} className="icon-cell text-primary"><i className="flaticon-mind"></i></Link>
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="dlab-tilte">Focus On Innovation</h3>
                                                <p>Innovation is essential for every organization, especially those who think they’re too big for it.</p>
                                            </div>
                                            <Link to={"#"} className="site-button btn-block d-flex justify-content-between"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </Card>
                                    </div>
                                </div>
                                <div className="row d-flex align-items-center">
                                    <div className="col-lg-5 col-md-12 m-b30">
                                        <h2 className="box-title m-t0 m-b20 font-40"><span className="font-weight-400">About </span><br />Our Company</h2>
                                        <p className="m-b20">Finesse Integrated Technologies is a leading international technology firm with focus on serving the needs of businesses organizations by providing leading-edge technology solutions and services that help our customers operate their businesses efficiently, increase employee productivity, and reduce cost of doing business...</p>
                                        <Link to={"./aboutus"} className="site-button button-md">Read More</Link>
                                    </div>
                                    <div className="col-lg-7 col-md-12">
                                        <img src={about11} className="radius-sm" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- About Us End --> */}
                        {/* <!-- Why Chose Us --> */}
                        <div className="section-full bg-blue-light content-inner explore-projects" style={{ backgroundImage: "url(" + bg3 + ")" }}>
							<div className="container">
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12 section-head text-center">
                                            <h2 className="m-b0 font-40"><span className="font-weight-400">Explore</span> Our Services</h2>
                                            <p className="m-b0">We help customers around the world become competitive – and stay competitive.</p>
                                        </div>
                                    </div>
                                    {/* <!-- blog post Carousel with no margin --> */}
                                    <div className="">
										<ExploreCarousel /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Why Chose Us End --> */}
                        <div className="section-full overlay-black-dark our-projects" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
                            <div className="container text-white">
                                <div className="row m-lr0 d-flex align-items-stretch">
                                    <div className="col-lg-4 col-md-4 p-lr0 d-flex ind-ser-info-bx">
                                        <div className="ind-service-info align-self-stretch">
                                            <span>01</span>
                                            <div className="ind-service-info-in">
                                                <h2><span>World Class</span>Technology</h2>
                                                
                                            </div>
                                            <Link to={"#"} className="site-button btn-block d-flex justify-content-between white outline outline-2"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 p-lr0 d-flex ind-ser-info-bx">
                                        <div className="ind-service-info align-self-stretch">
                                            <span>02</span>
                                            <div className="ind-service-info-in">
                                                <h2><span>Quality </span>Standard</h2>
                                            </div>
                                            <Link to={"#"} className="site-button btn-block d-flex justify-content-between white outline outline-2"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 p-lr0 d-flex ind-ser-info-bx">
                                        <div className="ind-service-info align-self-stretch">
                                            <span>03</span>
                                            <div className="ind-service-info-in">
                                                <h2><span>Productive</span>Capacity</h2>
                                            </div>
                                            <Link to={"#"} className="site-button btn-block d-flex justify-content-between white outline outline-2"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Our Portfolio --> */}
                        <div className="section-full bg-blue-light content-inner explore-projects" style={{ backgroundImage: "url(" + bg3 + ")" }}>
							<div className="container">
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12 section-head text-center">
                                            <h2 className="m-b0 font-40"><span className="font-weight-400">Our</span> Clients</h2>
                                            <p className="m-b0">Our client list speaks for itself. Since our earliest days, we’ve represented everything from start-ups to Fortune with that same partnership approach and dedication at the core of every engagement. Every day, since extablishment, we’ve been devoting our hearts and minds to our clients, achieving great successes and building lasting relationships.</p>
                                        </div>
                                    </div>
                                    {/* <!-- blog post Carousel with no margin --> */}
                                    <div className="">
                                    <ClientCarousel />
                                    </div>
                                </div>
                            </div>
                        </div>
                       
                       
                        {/* <!-- Our Portfolio END --> */}
                  
                        {/* <!-- Testimonials END --> */}
                        <div className="section-full add-to-call bg-primary p-tb30">
                            <div className="container">
                                <div className="d-lg-flex d-sm-block justify-content-between align-items-center">
                                    <h2 className="m-b10 m-t10 text-white">Reliable Engineering Takes Many Forms</h2>
                                    <div><Link to={"#"} className="site-button button-md white">Learn More</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- contact area END --> */}
                </div>                
                <Footer5 />
                 
            </>
        )
    }
}
export default Index5;