import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PageTitle from './../Layout/PageTitle';
import ImgCarousel from './../Element/ImgCarousel'
import ClientCarousel from './../Element/ClientCarousel'

//Images 
import bnr1 from './../../images/banner/bnr2.jpg';

import Footer5 from '../Layout/Footer5';
import Header3 from '../Layout/header3';

class Infrastructure extends Component {
    /* componentDidMount(){
        window.abc();
    } */
    render() {
        return (
            <> 
            <Header3 />            
            <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='CONSULTANCY AND ICT STRATEGY / SOLUTIONS' activeMenu='messaging' />  
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- Project Details --> */}
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="row m-b40">
                                    <div className="col-lg-6 col-md-6 m-b20">
                                        <h6 className="m-b10 m-t0">ONE OF THE ADVANTAGES OF MOVING BUSINESS TO THE CLOUD IS THAT IT PROVIDES COMPANIES WITH NOTEWORTHY SAVINGS.</h6>
                                        
                                        <p className="m-b10">Enterprises want to take advantage of the agility, security, speed and flexibility of hybrid cloud — but with that comes new knowledge and IT complexity. Finesse Tech offers highly skilled and professional Cloud Services support and consultation to its clients with an effective way to migrate, consume, and integrate cloud</p>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
										<ImgCarousel /> 
                                    </div>
                                </div>
                                <div className="row m-b10">                                    
                                    <div className="col-md-12 align-self-center">
                                        <p className="m-b0">with their traditional infrastructure for optimize productivity. Our team will assist you in reducing time and cost of cloud migration, thereby adding value, as well as giving the best worth to your IT infrastructure and IT unit. Other infrastructure and system services which we offer are Active Directory and electronic document management systems which can be configured to operate from the cloud and sync with your on premise infrastructure seamlessly. Having the capacity to manage files through the cloud makes it simple for businesses to track and oversee progress of assignments from any part of the world eliminating the restricted security and location barrier.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row m-b30">
                                    <div className="col-lg-4 col-md-12 align-self-center">
                                        <h5>Who We Are</h5>
                                        <h2 className="font-weight-700">Leading The Way In IT Consulting Business For Over 25 Years</h2>
                                    </div>
                                    <div className="col-lg-8 col-md-12">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="90" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">90%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Day Of Experience</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="49" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">90%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Awards Winning</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="65" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">90%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Happy Clients</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
								<ClientCarousel /> 
                            </div>
                        </div>
                    </div>
                    {/* <!-- contact area END --> */}
                </div>
                <Footer5 />
            </>
        )
    }
}
export default Infrastructure;