import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PageTitle from './../Layout/PageTitle';
import ImgCarousel from './../Element/ImgCarousel'
import ClientCarousel from './../Element/ClientCarousel'

//Images 
import bnr1 from './../../images/banner/bnr2.jpg';

import Footer5 from '../Layout/Footer5';
import Header3 from '../Layout/header3';

class Management extends Component {
    /* componentDidMount(){
        window.abc();
    } */
    render() {
        return (
            <> 
            <Header3 />            
            <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='IT MANAGEMENT' activeMenu='messaging' />  
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- Project Details --> */}
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="row m-b40">
                                    <div className="col-lg-6 col-md-6 m-b20">
                                        <h6 className="m-b10 m-t0">OFFERING FULL TIME, WEEKLY OR ON CALL SUPPORT ASSISTANCE TO YOUR INFRASTRUCTURE TO MEET YOUR NEEDS IS OUR WAY TO ENSURE YOUR BUSINESS STAYS ACTIVE IN THE TECHNOLOGY SECTOR.
</h6>
                                        
                                        <p className="m-b10">We provide a variety of support options to meet our clients’ specific assistive needs. Whether they have an IT team and require a weekly continuous and ongoing support for efficiency or no presence of any technical team and require the presence of a personal stationed in house at specific business periods to ensure the success of your transactions, we have the resources to deliver to the specific need of every customer. Other services include remote management, asset management, performance monitoring, security monitoring and update maintenance, Infrastructure diagnostics and troubleshooting, Configuration, deployment and installation, Upgrades and migration to new platforms. Each customer receives a report tailored towards their needs and success of their environment amongst other documentation which enables them keep track of changes and functionality of their Environment such as As-Is and Would-Be Documents, hand over notes/documents as well as Documented use case tests.</p>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
										<ImgCarousel /> 
                                    </div>
                                </div>
                                <div className="row m-b10">                                    
                                    <div className="col-md-12 align-self-center">
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row m-b30">
                                    <div className="col-lg-4 col-md-12 align-self-center">
                                        <h5>Who We Are</h5>
                                        <h2 className="font-weight-700">Leading The Way In IT Consulting Business For Over 25 Years</h2>
                                    </div>
                                    <div className="col-lg-8 col-md-12">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="90" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">90%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Day Of Experience</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="49" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">90%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Awards Winning</h5>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-6">
                                                <div className="icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white">
                                                    <svg className="radial-progress" data-percentage="65" viewBox="0 0 80 80">
                                                        <circle className="incomplete" cx="40" cy="40" r="35"></circle>
                                                        <circle className="complete" cx="40" cy="40" r="35" style={{"strokeDashoffset":"39.58406743523136"}}></circle>
                                                        <text className="percentage" x="50%" y="57%" transform="matrix(0, 1, -1, 0, 80, 0)">90%</text>
                                                    </svg>
                                                    <div className="icon-content">
                                                        <h5 className="font-weight-500">Happy Clients</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container">
								<ClientCarousel /> 
                            </div>
                        </div>
                    </div>
                    {/* <!-- contact area END --> */}
                </div>
                <Footer5 />
            </>
        )
    }
}
export default Management;