import React,{Component} from 'react';			

export default class ThemeButton extends Component{
	render(){
		return(
			<>
				<a href="https://web.whatsapp.com/" className="bt-buy-now theme-btn"><i className="ti-headphone-alt"></i><span>Chat with us</span></a>
				
			</>
		)
	}	
}
