import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import PageTitle from './../../Layout/PageTitle';


//Images
import bnr5 from './../../../images/banner/bnr5.jpg';

import Header3 from '../../Layout/header3';
import Footer5 from '../../Layout/Footer5';

const iconBox = [
    { icon: <i className="flaticon-devices" />, link: "/INFRASTRUCTURE", title: 'CLOUD, INFRASTRUCTURE AND SYSTEM SERVICES', body: "One of the advantages of moving business to the cloud is that it provides companies with noteworthy savings..." },
    { icon: <i className="flaticon-pen" />, link: "/consultancy", title: 'CONSULTANCY AND ICT STRATEGY / SOLUTIONS',body: "Assisting our customers increase efficiency in their consumption of their technology tools; both software and hardware..." },
    { icon: <i className="flaticon-bar-chart" />, link: "/licensing", title: 'LICENSING, CAPACITY BUILDING AND TRAINING',body: "Focused in promoting knowledge sharing and the further development of its customers both at the public and private sectors..." },
    { icon: <i className="flaticon-file" />,link: "/messaging", title: 'MESSAGING,COLLABORATION & UC SOLUTIONS', body: "Having a platform to communicate, exchange documents and ideas in a secured but yet optimized and easy to use form is part..." },
    { icon: <i className="flaticon-notebook" />, link: "/Project", title: 'PROJECT AND HUMAN RESOURCE MANAGEMENT', body: "we manage human capital of organizations focusing on implementing policies to move globally and form more diverse teams..." },
    { icon: <i className="flaticon-team" />, link: "/management", title: 'IT MANAGEMENT', body: "Offering full time, weekly or on call support assistance to your infrastructure to meet your needs is our way to ensure your business stays..." },
];

class Service extends Component {


    render() {
        return (
            <>
                <Header3 />

                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr5 + ")" }}>
                        <PageTitle motherMenu='Our Services' activeMenu='Our Services' />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full content-inner">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="box-title m-tb0">Our Services<span className="bg-primary"></span></h2>
                                    <p>We help customers around the world become competitive – and stay competitive. We combine best-in-class software engineering with digital strategy and experience design, Information Technology consulting and technology innovation services.

                                    </p>
                                </div>
                            </div>
                            <div className="container">
                                <div className="row ">
                                    {iconBox.map((data, index) => (
                                        <div className="col-md-4 col-sm-6 m-b30 " key={index}>
                                            <div className="icon-bx-wraper expertise  bx-style-1 p-a30 center">
                                                <div className="icon-lg m-b20"> <Link to={"#"} className="icon-cell">{data.icon}</Link> </div>
                                                <div className="icon-content">
                                                    <h5 className="dlab-tilte text-uppercase"><Link to={"#"}>{data.title}</Link></h5>
                                                    <p>{data.body}</p>
                                                    <Link to={data.link} className="site-button radius-xl outline outline-2">Read More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* <!-- contact area END --> */}
                </div>

                <Footer5 />
            </>
        )
    }
}
export default Service;