import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import PageTitle from './../Layout/PageTitle';


//Images
import  about9 from './../../images/about/pic9.jpg';


import bnr from './../../images/banner/bnr2.jpg';
import Footer5 from '../Layout/Footer5';
import Header3 from '../Layout/header3';

class OurEthics extends Component {
    render() {
        return (
            <>
               <Header3 />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Our Ethics' activeMenu='Our Ethics' />
					</div>	
                    <div className="content-block">
                        <div className="section-full content-inner-2">
                            
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-5 col-md-4 about-img">
                                        <img src={about9} data-tilt alt="" />
                                    </div>
                                    <div className="col-lg-7 col-md-8">
                                        <div className="abuot-box left row m-lr0 ">
                                            <div className="col-lg-4">
                                                <h4 className="text-gray-dark">GLOBAL BUSINESS CONDUCT </h4>
                                            </div>
                                            <div className="col-lg-8">
                                                <p>Finesse Tech highly regards and takes into cognisance the diverse customers with their business practices and cultures that affect business activities in compliance with specifications required for excellent results that stand out. Finesse Tech operates within the acceptable laws and regulations that govern and satisfy the laws of Nigeria and complies with the laws guiding other places where it carries out business engagements. </p>
                                                </div>

                                                <div className="col-lg-4">
                                                <h4 className="text-gray-dark">INTELLECTUAL PROPERTY  </h4>
                                            </div>
                                            <div className="col-lg-8">
                                                <p>Finesse Tech complies with laws and regulations guiding the rights and usage of its intellectual property as well as that of others’ which include inventions, patents, trademarks, service marks, copyrights and trade secrets.  </p>
                                                </div>
                                                <div className="col-lg-4">
                                                <h4 className="text-gray-dark">FAIR COMPETITION AND ANTITRUST </h4>
                                            </div>
                                            <div className="col-lg-8">
                                                <p>The ethics of our operations comply with laws that promote salutary working environment across our value chain and build healthy competition. The Antitrust law interdict operations that could restrict competition and prevent trade and we carry out our business in accordance to these laws.  </p>
                                                </div>
                                                <div className="col-lg-4">
                                                <h4 className="text-gray-dark">REGULATORY INVESTIGATIONS, INSPECTIONS AND INQUIRIES </h4>
                                            </div>
                                            <div className="col-lg-8">
                                                <p>We carry out our operations with integrity, and transparency while being direct and honest in all our discussions with existing and intended clients. All inspections and inquiries on Finesse Tech activities are conducted with Legal and Corporate guidelines in response to appropriate request for information.  </p>
                                                </div>
                                                <div className="col-lg-4">
                                                <h4 className="text-gray-dark">ANTI-CORRUPTION  </h4>
                                            </div>
                                            <div className="col-lg-8">
                                                <p>Finesse Tech takes zero tolerance to bribery and corruption of any kind in dealing with individuals and officials in the public and private sector. 

We operate in accordance to standards set by the Independent Corrupt Practices and other Related Offences Commission (ICPC) Act Cap C31, anti-corruption, anti-money laundering and similar laws that guide all countries of operation. 

Finesse employees will constantly get trainings on the ethics and laws guiding its operations. No employee of Finesse Tech or representative is expected to break this policy in any way by taking a bribe or kickback. 

  </p>
                                                </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer5 />
            </>
        )
    }
}
	


export default OurEthics;